import { deepFreeze } from "@/utils/structure";

export enum UserSegment {
  NewItemsSender = "New items sender",
  SmallRelocator = "Small relocator",
  BigRelocator = "Big relocator",
  SellingEcommerce = "Selling e-commerce",
  Gifting = "Gifting",
  Corporate = "Corporate",
  ReturnsRepairs = "Returns/Repairs",
  Others = "Others",
}

const USER_SEGMENT_ARTICLES = deepFreeze({
  1: {
    title: "Relocation services to US",
    crispArticleSlug: "relocation-services-to-us-6ybqq",
  },
  2: {
    title: "Relocation services to UK",
    crispArticleSlug: "relocation-services-to-uk-2bswhm",
  },
  3: {
    title: "Relocation services to Germany",
    crispArticleSlug: "relocation-services-to-de-1fxhzbc",
  },
  4: {
    title: "Storage Add-On for Relocators",
    crispArticleSlug: "storage-add-on-for-relocators-wtsvfc",
  },
  5: {
    title: "Self-Packing For Relocators",
    crispArticleSlug: "self-packing-for-relocators-1ayearu",
  },
  6: {
    title: "E-Commerce Store Integrations",
    crispArticleSlug: "e-commerce-store-integrations-l85v7k",
  },
  7: {
    title: "Common E-Commerce Add-Ons",
    crispArticleSlug: "common-e-commerce-add-ons-19ovng4",
  },
  8: {
    title: "Insurance for E-Commerce",
    crispArticleSlug: "insurance-for-e-commerce-ah6huv",
  },
  9: {
    title: "Submitting Recipient Details",
    crispArticleSlug: "submitting-recipient-details-1l4d1mx",
  },
  10: {
    title: "Duties & Taxes for E-Commerce",
    crispArticleSlug: "duties-taxes-for-e-commerce-i1xus7",
  },
  11: {
    title: "Polymailers & Documents",
    crispArticleSlug: "polymailers-documents-1e8942s",
  },
  12: {
    title: "Payment Methods for Corporates",
    crispArticleSlug: "payment-methods-for-corporates-zl9u3c",
  },
  13: {
    title: "Feedback from Corporates",
    crispArticleSlug: "feedback-from-corporates-1t3xagr",
  },
  14: {
    title: "Amazon Labels for Returns",
    crispArticleSlug: "amazon-labels-for-returns-j28vxn",
  },
  15: {
    title: "Delivery Address for Returns",
    crispArticleSlug: "delivery-address-for-returns-tbrw7l",
  },
  16: {
    title: "Pick-Ups",
    crispArticleSlug: "pick-ups-wkd7ya",
  },
  17: {
    title: "Drop-Off",
    crispArticleSlug:
      "may-i-come-down-to-your-warehouse-to-drop-off-my-parcel-5u2nus",
  },
  18: {
    title: "Packing Services",
    crispArticleSlug: "what-packing-services-do-you-offer-1uaduyx",
  },
  19: {
    title: "What Gifts Can I Ship?",
    crispArticleSlug: "what-gifts-can-i-ship-163agak",
  },
  20: {
    title: "Gift Shipping Tips",
    crispArticleSlug: "tips-on-shipping-gifts-ux2ynr",
  },
  21: {
    title: "Gift Packing Services",
    crispArticleSlug: "gift-packing-services-vtpyyg",
  },
  22: {
    title: "Packing Gifts Yourself",
    crispArticleSlug: "packing-gifts-yourself-1s7i086",
  },
  23: {
    title: "Duties & Taxes for Gifts",
    crispArticleSlug: "duties-taxes-for-gifts-1arvuk2",
  },
});

export const determineUserSegment = (formResponse: any): UserSegment => {
  const useCase = formResponse.useCase;

  switch (useCase) {
    case "Sending personal items":
      if (formResponse.personalItemsType === "New") {
        return UserSegment.NewItemsSender;
      }
      if (formResponse.personalItemsType === "Used") {
        if (formResponse.personalItemsEstimatedWeight === ">50 KG") {
          return UserSegment.BigRelocator;
        } else {
          return UserSegment.SmallRelocator;
        }
      }
      break;
    case "Selling e-commerce":
      return UserSegment.SellingEcommerce;
    case "Gifting":
      return UserSegment.Gifting;
    case "Corporate":
      return UserSegment.Corporate;
    case "Returns/Repairs":
      return UserSegment.ReturnsRepairs;
    case "Others":
      return UserSegment.Others;
    default:
      return UserSegment.Others;
  }
};

export const SEGMENT_QUESTIONS = {
  "Selling e-commerce": [
    {
      id: "ecommerceVolume",
      text: "What is your shipment volume?",
      type: "dropdown",
      options: [
        "1 per quarter",
        "1-5 per month",
        "6-10 per month",
        "11-30 per month",
        ">30 per month",
      ],
      required: true,
    },
    {
      id: "ecommerceShipmentSize",
      text: "What is your typical shipment size?",
      type: "dimensions",
      required: true,
    },
    {
      id: "ecommercePlatform",
      text: "Can you tell us more about your business, or do you have a business platform you can share?",
      type: "textarea",
      required: true,
      placeholder:
        "e.g. My business sells customized T-shirts. My website is www.amazon.com/my-store. I'm looking for a logistics partner to ...",
    },
    {
      id: "ecommerceContact",
      callCodeId: "ecommerceContactCallCode",
      text: "Is there a contact number we can reach you at?",
      type: "phone",
      required: true,
    },
  ],
  "Sending personal items": [
    {
      id: "personalItemsType",
      text: "What type of items are you sending?",
      type: "buttons",
      options: ["New", "Used"],
      required: true,
    },
    {
      id: "personalItemsEstimatedWeight",
      text: "How much will you ship?",
      type: "dropdown",
      options: ["1-20 KG", "21-50 KG", ">50 KG"],
      required: true,
    },
    {
      id: "personalItemsPackStatus",
      text: "Have you packed your items?",
      type: "buttons",
      options: ["Yes", "No"],
      required: true,
    },
  ],
  Gifting: [
    {
      id: "giftingOccasion",
      text: "What's the occasion?",
      type: "dropdown",
      required: true,
      options: [
        "Lunar New Year",
        "Valentine's Day",
        "Hari Raya",
        "Mid-Autumn Festival",
        "Christmas",
        "Birthday",
        "Anniversary",
        "I just feel like it",
        "Others",
      ],
    },
  ],
  Corporate: [
    {
      id: "corporatePurpose",
      text: "Tell us more about your shipment purpose (Optional)",
      type: "textarea",
      placeholder:
        "E.g. I am sending my laptop back to my company's IT team to get it fixed.",
    },
  ],
  "Returns/Repairs": [],
  Others: [],
};

type UserSegmentResources = {
  [key in UserSegment]?: {
    title: string;
    crispArticleSlug: string;
  }[];
};

const newItemsSenderAndGifterResources = [19, 20, 21, 22, 23, 16].map(
  (id) => USER_SEGMENT_ARTICLES[id]
);

export const SEGMENT_RESOURCES: UserSegmentResources = {
  [UserSegment.NewItemsSender]: newItemsSenderAndGifterResources,
  [UserSegment.Gifting]: newItemsSenderAndGifterResources,
  [UserSegment.SmallRelocator]: [1, 2, 3, 16].map(
    (id) => USER_SEGMENT_ARTICLES[id]
  ),
  [UserSegment.BigRelocator]: [1, 2, 3, 4, 5].map(
    (id) => USER_SEGMENT_ARTICLES[id]
  ),
  [UserSegment.SellingEcommerce]: [6, 7, 8, 9, 10, 11, 16].map(
    (id) => USER_SEGMENT_ARTICLES[id]
  ),
  [UserSegment.Corporate]: [12, 13].map((id) => USER_SEGMENT_ARTICLES[id]),
  [UserSegment.ReturnsRepairs]: [14, 15].map((id) => USER_SEGMENT_ARTICLES[id]),
  [UserSegment.Others]: [16, 17, 20, 18].map((id) => USER_SEGMENT_ARTICLES[id]),
};
